






























































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import echarts from "echarts";
import { BigData } from "@/common/Views/bigData/bigData";
import { Form } from "@/common/Views/basics/gradeManage/gradeManage";
import { Util } from "@/common/Views/comm/util";
import { DataECharts } from "@/common/echarts/DataECharts";
import { time } from "highcharts";
import { BasicsClass } from "../../../../common/BasicsClass";

@Component
export default class HelloWorld extends Vue {
    @Prop() form!: any;
    @Prop() refreshModule!: string;
    @Prop() sendGroupCodeId: any;
    @Prop() sendFarmId: any;
    @Prop() sendFarmName: any;
    @Prop() sendGroupName: any;

    private ToNewPage() {
        if (this.sendGroupCodeId == "") {
            this.$router.push({
                path: "/infoRegister",
                query: {
                    sendFarmId: this.sendFarmId,
                    sendFarmName: this.sendFarmName
                }
            });
        } else {
            this.$router.push({
                path: "/infoRegister",
                query: {
                    sendGroupCodeId: this.sendGroupCodeId,
                    sendGroupName: this.sendGroupName
                }
            });
        }
    }
    // @Prop() animalTypeId!: any;

    // private form: form = {
    //   animalTypeId: 1,
    //   groupCode: "0",
    //   farmId: "",
    // };

    private hover: boolean = false;
    private hover2: boolean = false;

    private Dialog1: boolean = true;
    private Dialog2: boolean = false;
    private Dialog3: boolean = true;
    private Dialog4: boolean = false;
    private colors: any = [
        "#009DFF",
        "#024FEB",
        "#A672FB",
        "#03EDC7",
        "#523BFF",
        "#DE942B",
        "#242AC9",
        "#FFF04B"
    ];
    private count: number = 0;
    private animalname: string = "猪";
    private data1: any = {};
    private data2: any = {};
    // @Prop()echart1height!: number;

    private onlineMap: string = "";
    private unonlineMap: string = "";
    private created(): void {
        // this.getinsuranceLivestock();
    }

    private timeout: any = "";
    private mouseleavetimeout(): void {
        // clearTimeout(this.timeInterval);
        this.timeout = setTimeout(() => {
            this.hover = false;
            clearTimeout(this.timeout);
        }, 500);
    }

    //未盘点原因离开
    private timeout2: any = "";
    private mouseleavetimeout2(): void {
        this.timeout2 = setTimeout(() => {
            this.hover2 = false;

            clearTimeout(this.timeout2);
        }, 500);
    }

    private timeInterval: any = "";
    private scroll: number = -1;
    private onmovelist(): void {
        this.timeInterval = setInterval(() => {
            if (!this.$refs.msglist1) {
                return;
            }
            var clientHeight = (this.$refs.msglist1 as any).clientHeight;
            var scrollTop = (this.$refs.msglist1 as any).scrollTop;
            var scrollHeight = (this.$refs.msglist1 as any).scrollHeight;
            if (scrollTop + clientHeight == scrollHeight) {
                // alert("到底部了！");
                this.scroll = -1;
            }
            this.scroll++;
            (this.$refs.msglist1 as any).scroll(0, 1 * this.scroll);
        }, 20);
    }

    private timeInterval2: any = "";
    private scroll2: number = -1;
    private onmovelist2(): void {
        this.timeInterval2 = setInterval(() => {
            if (!this.$refs.msglist2) {
                return;
            }
            var clientHeight = (this.$refs.msglist2 as any).clientHeight;
            var scrollTop = (this.$refs.msglist2 as any).scrollTop;
            var scrollHeight = (this.$refs.msglist2 as any).scrollHeight;
            if (scrollTop + clientHeight == scrollHeight) {
                // alert("到底部了！");
                this.scroll2 = -1;
            }
            this.scroll2++;
            (this.$refs.msglist2 as any).scroll(0, 1 * this.scroll2);
        }, 20);
    }

    private mounted(): void {
        this.onmovelist();
        this.onmovelist2();
    }
    private mouseover(): void {
        this.hover = true;
        clearTimeout(this.timeout);
        clearTimeout(this.timeInterval);
    }

    private mouseovertwo(): void {
        clearTimeout(this.timeout2);

        this.hover2 = true;
    }

    private mouseover2(): void {
        this.hover = true;

        clearTimeout(this.timeout);
        clearTimeout(this.timeInterval);
    }

    //未盘点原因鼠标位于弹窗
    private mouseover3(): void {
        this.hover2 = true;
        clearTimeout(this.timeout2);
        clearTimeout(this.timeInterval2);
    }
    //未盘点原因鼠标离开弹窗
    private mouseleave2(): void {
        this.onmovelist2();
        this.hover2 = false;
    }

    private mouseleave(): void {
        this.onmovelist();
        this.hover = false;
    }
    @Watch("form", { immediate: true, deep: true })
    private change(): void {
        // this.form.groupCode = this.region;
        this.getinsuranceLivestock();
        this.getCount();
    }

    private allFarmAnimalCount: number = 0; // 存栏数量
    private allAnimalCount: number = 0; // 投保数
    private allLoanMoney: number = 0;

    private getCount() {
        new BasicsClass().BasicGet(
            "/animal/web/bigScreenForInsuranceNew/getAnimalCount",
            {
                groupCode: this.form.id,
                farmId: this.form.farmId,
                animalTypeId: this.form.currentType
            },
            false,
            true,
            (res: any) => {
                // console.log('监管数量 data: ', res)
                this.allFarmAnimalCount = res.data.unOutAnimalCount; // 存栏数量
                this.allAnimalCount = res.data.allAnimalCount; // 投保数
                this.allLoanMoney = res.data.allLoanMoney;
                console.log("投保数", this.allAnimalCount);
                this.$emit('dataFromChild', { allAnimalCount: this.allAnimalCount });
            }
        );
    }

    @Watch("refreshModule")
    private change2(): void {
        if (this.refreshModule == "Echarts8") this.getinsuranceLivestock();
    }
    // @Watch("animalTypeId")
    // private change2(): void {
    //   this.form.animalTypeId = this.animalTypeId;

    //   this.getinsuranceLivestock();
    // }

    private echarts(): void {
        DataECharts.pie2(
            <HTMLDivElement>document.querySelector("#bigDataCenterLeft1"),
            this.data1
        );

        DataECharts.pie4(
            <HTMLDivElement>document.querySelector("#bigDataCenterLeft2"),
            this.data2
        );
    }

    private typeShow: number = 1;
    private detail1: any[] = [];
    private detail2: any[] = [];
    private alert: boolean = false;
    private noInventoryCause: any = {
        fallOut: 0,
        illicitLeave: 0,
        lowBattery: 0,
        other: 0,
        count: 0
    };
    private fallOutList: any[] = [];
    private illicitLeaveList: any[] = [];
    private lowBatteryList: any[] = [];
    private otherList: any[] = [];

    private multiData: any[] = [];
    private singleData: any[] = [];

    private nulldata: boolean = false;
    private pandianNow: number = 0;
    private pandianNotNow: number = 0;
    private getinsuranceLivestock(): void {
        new BigData().getTakeStockData((data: any) => {
            this.detail1 = [];
            this.detail2 = [];
            this.data1 = data.data.haveInventoryTypeAndCount;
            this.data2 = data.data.noInventoryTypeAndCount;
            this.pandianNow = data.data.haveInventoryCount; // 实时盘点
            this.pandianNotNow = data.data.noInventoryCount; // 超时盘点
            for (const i in this.data1) {
                this.detail1.push({ value: this.data1[i], name: i });
            }
            for (const i in this.data2) {
                this.detail2.push({ value: this.data2[i], name: i });
            }
            if (Object.keys(this.data1).length > 4) {
                let primary =
                    -0.26 * (Object.keys(this.data1).length - 2) + "rem";
                document.body.style.setProperty("--primary", primary);
                this.onlineMap = "onlineMap";
            } else {
                document.body.style.setProperty("--primary", "0px");
                this.onlineMap = "";
            }
            if (Object.keys(this.data2).length > 4) {
                let primary2 =
                    -0.26 * (Object.keys(this.data2).length - 2) + "rem";
                document.body.style.setProperty("--primary2", primary2);
                this.unonlineMap = "unonlineMap";
            } else {
                document.body.style.setProperty("--primary2", "0px");
                this.unonlineMap = "";
            }
            this.echarts();
        }, this.form);
        //获取弹窗1

        new BigData().noInventoryItem3((res: any) => {
            if (res.data.type == "multi") {
                if (res.data.multiData.length == 0) {
                    this.nulldata = false;
                } else {
                    this.nulldata = true;
                }
                this.Dialog1 = true;
                this.Dialog2 = false;
                this.multiData = res.data.multiData;
            } else if (res.data.type == "single") {
                if (res.data.singleData.length == 0) {
                    this.nulldata = false;
                } else {
                    this.nulldata = true;
                }
                this.Dialog1 = false;
                this.Dialog2 = true;
                this.singleData = res.data.singleData;
            }
        }, this.form);

        //获取弹窗2
        new BigData().noInventoryCauseItem4((res: any) => {
            this.fallOutList = [];
            this.illicitLeaveList = [];
            this.lowBatteryList = [];
            this.otherList = [];
            if (res.data.type == "multi") {
                this.Dialog3 = true;
                this.Dialog4 = false;
                if (res.data.fallOutList.length > 0) {
                    for (let i of res.data.fallOutList) {
                        let obj: any = {
                            farmName: "",
                            sum: 0
                        };
                        obj.farmName = Object.keys(i)[0];
                        obj.sum = Object.values(i)[0];
                        this.fallOutList.push(obj);
                    }
                }

                if (res.data.illicitLeaveList.length > 0) {
                    for (let i of res.data.illicitLeaveList) {
                        let obj: any = {
                            farmName: "",
                            sum: 0
                        };
                        obj.farmName = Object.keys(i)[0];
                        obj.sum = Object.values(i)[0];
                        this.illicitLeaveList.push(obj);
                    }
                }

                if (res.data.lowBatteryList.length > 0) {
                    for (let i of res.data.lowBatteryList) {
                        let obj: any = {
                            farmName: "",
                            sum: 0
                        };
                        obj.farmName = Object.keys(i)[0];
                        obj.sum = Object.values(i)[0];
                        this.lowBatteryList.push(obj);
                    }
                }

                if (res.data.otherList.length > 0) {
                    for (let i of res.data.otherList) {
                        let obj: any = {
                            farmName: "",
                            sum: 0
                        };
                        obj.farmName = Object.keys(i)[0];
                        obj.sum = Object.values(i)[0];
                        this.otherList.push(obj);
                    }
                }
                // console.log(this.fallOutList);
            } else if (res.data.type == "single") {
                this.Dialog3 = false;
                this.Dialog4 = true;
                if (res.data.data.fallOut.length > 0) {
                    this.fallOutList = res.data.data.fallOut;
                }
                if (res.data.data.illicitLeave.length > 0) {
                    this.illicitLeaveList = res.data.data.illicitLeave;
                }
                if (res.data.data.lowBattery.length > 0) {
                    this.lowBatteryList = res.data.data.lowBattery;
                }
                if (res.data.data.other.length > 0) {
                    this.otherList = res.data.data.other;
                }
            }
        }, this.form);

        //获取未盘点原因数量
        new BigData().noInventoryCause2((res: any) => {
            this.noInventoryCause = res.data;
        }, this.form);
        //获取提示
        new BigData().todayOutColumn2((res: any) => {
            // console.log(res.data);
            this.alert = res.data;
        }, this.form);
    }
}
